<template>
  <div class="wrap">
    <div>
      当前位置：<span v-for="(item, index) in crumbs" :key="index">
        <router-link :to="item.path" v-if="item.path"
          >{{ item.name }}
        </router-link>
        <span v-else>{{ item.name }}</span>
        <span v-if="index < crumbs.length - 1"> / </span>
      </span>
    </div>
  </div>
</template>
<script setup>
import { reactive, computed, defineProps } from 'vue'
import { useRoute, useRouter } from 'vue-router'

const route = useRoute()
const router = useRouter()

const props = defineProps({
  crumbs: {
    type: Array,
    required: false,
  },
})

const crumbs = computed(() => {
  if (!router || !router.getRoutes) {
    return
  }
  const allRoutes = router.getRoutes()
  const path = route.path
  const findRoute = allRoutes.find(item => {
    if (item.path == path) {
      return true
    } else {
      if (item.path.indexOf(':') != -1) {
        const str = item.path.replace(/\:[^\/]+/, '[^\/]+')
        const reg = new RegExp(str)

        if (reg.exec(path)) {
          return true
        }
      }
    }
    return false
  })
  console.log('findRote', findRoute, allRoutes)
  let res = [{ name: '首页', path: '/' }]

 
  if (findRoute) {
    
    if (findRoute.path.indexOf(':') != -1) {
      let path = findRoute.path.replace(/\:[^\/]+/, '')
      path = path.replace(/\/$/gi, '')
      res.push({ name: findRoute.meta.name_cn, path })
    } else {
      res.push({ name: findRoute.meta.name_cn })
    }
  }
  if (props.crumbs) {
    res = res.concat(props.crumbs)
  }
  if( findRoute.path.indexOf('news')!=-1){
    const queryType = route.query.type;
    if( queryType ){
      const map = {
        'student':'大学生创业',
        'soldier':'退伍军人创业',
        'sick':'残疾人创业'
      }
      if( map[queryType]){
        res.push({
          name:map[queryType]
        })
      }
    }
    console.log('queryType')
  }
  console.log('res===', res)
  return res
})
</script>
<style lang="scss" scoped>
.wrap {
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 12px;
  color: #5d5d5d;
  a {
    text-decoration: none;
  }
}
</style>
