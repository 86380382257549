import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus' //全局引入
import 'element-plus/dist/index.css' // 正确的样式路径
import 'dayjs/locale/zh-cn'
import locale from 'element-plus/es/locale/lang/zh-cn'

import '@/assets/icons'
import SvgIcon from '@/components/SvgIcon/index.vue' // svg组件
import Breadcrumbs from '@/components/Breadcrumbs.vue'
require('./libs/demo')

const app = createApp(App)

app.component('svg-icon', SvgIcon)
app.component('breadcrumbs', Breadcrumbs)
app.use(ElementPlus, { locale })

app.use(store).use(router).mount('#app')
