<template>
  <div class="wrap">
    <div class="title-wrap">
      <span class="title" @click="handleLoad(state.id)">{{ state.title }}</span>
      <span @click="handleLoad(state.id)" v-if="loading==-1">点击查看</span>
    </div>
    <div class="info" v-if="loading != -1" :loading="loading != 0">
      下载地址：
      <PayItem @payed="handlePay(state.id)" :payed="state.info.viewPayAmount==''" :price="state.info.viewPayAmount" :chargeType="9" :targetId="state.id">
        <span>{{ state.info.downloadUrl }}</span>
      </PayItem>
    </div>
  </div>
</template>
<script setup>
import { ref, defineProps, reactive, computed } from 'vue'
import { useRoute } from 'vue-router'
import PayItem from '@/components/PayItem.vue'
import {Api } from '@/services/http'
const props = defineProps({
  data: {
    type: Object,
    required: false,
  },
})
const loading = ref(-1)
const route = useRoute()
const prjUrl = computed(() => {
  return '/fund/' + state.id
})
const isNewWindow = computed(() => {
  return route.path.indexOf('ucenter') != -1 ? '_blank' : ''
})
const state = reactive({ ...(props.data || {}), info: {} })

const handlePay = (id)=>{
  handleLoad(id)
  state.info.viewPayAmount = ''
}
const handleLoad = async id => {
  loading.value = 1
  const data = await Api.get(`/client-api/toolbox/get?id=${id}`)
  state.info = {...data}
  console.log('state',state)
  loading.value = 0
}
</script>
<style lang="scss" scoped>
.wrap {
  padding: 10px;
  border: 1px solid #e5e5e5;
  margin-bottom: 12px;
  .title-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      &:hover {
        color: #0093ef;
      }
      cursor: pointer;
    }
  }
  .info {
    font-size: 14px;
  }
  ::v-deep .pay-item{
    border:none;
    margin-bottom:0
  }
}
</style>
