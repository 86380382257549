import { createStore } from "vuex";
import base from './module/base'
import user from './module/user'
import suggest from './module/suggest'
export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    base, user, suggest
  },
});
