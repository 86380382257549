<template>
  <div class="wrap">
    <el-checkbox v-model="agree" label=" " size="large" />
    <span
      >同意
      <router-link to="/about/service" target="_blank"
        >《用户协议》</router-link
      >
      
      <router-link to="/about/privacy" target="_blank">《隐私协议》</router-link
      >的所有条款</span
    >
  </div>
</template>
<script setup>
import { ref, defineModel } from 'vue'
const agree = defineModel('agree')
console.log('agres',agree)
</script>
<style lang="scss" scoped>
.wrap {
  display: flex;
  clear: both;
  width: 100%;
  text-align: left;
  align-items: center;
  a {
    color: #237ece;
  }
}
</style>
