import { Api } from '@/services/http'
let userInfo = {}

export const getUserInfo = async () => {
  const token = localStorage.getItem('token');
  if (!token) {
    return null;
  } else {
    let info:any = null;
    try{
      info = JSON.parse(localStorage.getItem('userinfo') || '{}')
    }catch(err){}
    if (!info || !info.id) {
      info = await Api.get(`/client-api/userInfo/userInfo`)
      if (info && info.id) {
        localStorage.setItem('userinfo', JSON.stringify(info))
      }
    }
    return info;
  }
}
export const clearUserInfo = ()=>{
  localStorage.setItem('userinfo','{}')
}
export const logout = async()=>{
  localStorage.setItem('token','')
  localStorage.setItem('userinfo','{}')
}
export default {
  getUserInfo,
  clearUserInfo,
  logout
}
