import { reactive, computed, watch, toRefs } from 'vue'

export const useStoreData = ( initState:any = null, computedFunc:()=> any )=>{
  const state = reactive<any>( initState );
  const value = computed( computedFunc )
  const realValue = computed(()=>{
    if( state.value ){
      return state.value;
    }
    return value.value;
  })
  watch( value , (newValue )=>{
    state.value = newValue;
  })
  return realValue;
}
