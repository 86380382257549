const base = {
  namespaced: true,
  state: {
    businessItems:[]
  },
  getters: {
    businessItems(state:any) {
      return state.businessItems 
    }
  },
  mutations: {
    setBusinessItems (state:any, items:any[]) {
      console.log('set.....',items)
      state.businessItems = [...items]
    }
  },
  actions: {
    updateItems ( state:any, items:any[]) {
      state.commit('setBusinessItems', items)
    }
  }
}
export default base
