const run = () => {
  const host = document.location.host
  if (!/dpxtd/.exec(host) && false) {
    return
  }
  setInterval(() => {
    let demo = document.querySelector('#dpx-demo')
    if (!demo) {
      demo = document.createElement('div')
      demo.id = 'dpx-demo'
      document.body.appendChild(demo)
      demo.innerHTML = 'DEMO'
      console.log('append')
    }
  }, 1000)
  console.log('demo is run')
}
run()
