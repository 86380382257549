<template>
  <div class="item item-group">
    <router-link :to="prjUrl" class="poster">
      <img :src="state.groupAvatar" />
    </router-link>

    <div class="info">
      <router-link :to="prjUrl" class="title">{{state.groupName}}</router-link>
      <div class="desc">
        {{state.groupPurpose}}
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, defineProps, reactive, computed } from 'vue'

const props = defineProps({
  data: {
    type: Object,
    required: false,
  },
})
const rate = ref(0)
const prjUrl = computed(() => {
  return '/resource/' + state.id
})
const state = reactive({ ...(props.data || {}) })
rate.value = Number(state.userStarLevel) || 0
</script>
<style lang="scss" scoped>
.item {
  padding: 5px;
  display: flex;
  box-sizing: border-box;
  .poster {
    display: block;
    width: 110px;
    height: 110px;
    flex-basis: 110px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .info {
    text-align: left;
    padding-left: 15px;
    flex:1;
    .title {
      font-size: 16px;
      font-weight: bold;
      padding-bottom: 10px;
    }
    .desc {
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3; /* 显示的行数 */
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 1.5em; /* 每行的高度 */
      max-height: 4.5em; /* line-height * 行数 */
      color:#ababab;
    }
  }
}
</style>
