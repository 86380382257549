<template>
  <div class="helper">
    <div class="item">
      <svg-icon icon="icon_help_kf"></svg-icon>
      <span>客服</span>
      <div class="expand">
        <span>电话：18807180682</span>
      </div>
    </div>
    <div class="item">
      <svg-icon icon="icon_help_fk"></svg-icon>
      <span>反馈</span>
    </div>
    <div class="item">
      <svg-icon icon="icon_help_fl"></svg-icon>
      <span>福利</span>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.helper {
  position: fixed;
  z-index: 100;
  top: 400px;
  right: 15px;
  box-shadow: 1px 1px 11px rgb(212 212 212 / 25%);
  background-color: white;
  padding: 15px;
  border-radius: 15px;
  box-shadow: 1px 1px 11px rgba(212, 212, 212, 0.25);
  background-color: white;

  .svg-icon {
    width: 24px;
    height: 24px;
  }
  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    &:hover{
      color:rgb(5, 91, 231);
    }
  }
  
  .item + .item{
    margin-top: 15px;
  }
  .expand{
    display: none;
    position: absolute;
    left: -218px;
    background: white;
    padding: 10px;
    width: 200px;
    box-sizing: border-box;
    border-radius: 10px;
    box-shadow: 1px 1px 11px rgba(212, 212, 212, 0.25);
  }
  .item:hover .expand{
    display: block;
  }
}

</style>
