import { Api } from '@/services/http'
// 随机推荐
const rand = (arr:any, length = 10) => {
  const res = []
  if (arr.length < length * 2) {
    return arr.slice(0, length);
  }
  for (let i = 0; i < length; i++) {
    const rnd = Math.floor(Math.random() * arr.length)
    res.push(arr[rnd])
  }
  return res;
}
const base = {
  namespaced: true,
  state: {
    projects: [],
    resources: [],
    news: []
  },
  getters: {
    businessItems(state: any) {
      return state.businessItems
    }
  },
  mutations: {
    setBusinessItems(state: any, items: any[]) {
      console.log('set.....', items)
      state.businessItems = [...items]
    }
  },
  actions: {
    loadSuggest: async (state: any) => {

    },

    checkSuggest: async (state: any) => {
      if (!state.state.projects.length) {
        const data: any = await Api.post('/client-api/project/list', 'size=100')
        state.state.projects = rand(data.list,5)
      }
      if (!state.state.news.length) {
        const data: any = await Api.post('/client-api/news/list', 'size=100&groupOriented=1')
        state.state.news = rand(data.list)
      }
      if (!state.state.resources.length) {
        const data: any = await Api.post('/client-api/resourceGroup/list', 'size=100')
        state.state.resources = rand(data.list,5)
      }
    },

  }
}
export default base
