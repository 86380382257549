import * as user from '@/libs/user'
const base = {
  namespaced: true,
  state: {
    info: {}
  },
  getters: {
    userInfo(state: any) {
      return state.info
    }
  },
  mutations: {
    updateUserInfo(state: any, info: any) {
      state.info = { ...info }
    }
  },
  actions: {
    updateUserInfo:async(state:any)=>{
      user.clearUserInfo();
      state.commit('updateUserInfo',{})
      state.dispatch('checkUserInfo')
    },
    checkUserInfo: async(state: any)=>{
      const info = await user.getUserInfo();
      console.log('checkInfo',info)
      if( info ){
        state.commit('updateUserInfo', info)
      }
    },
    logout:async (state:any)=>{
      await user.logout();
      state.commit('updateUserInfo', {})
    }
  }
}
export default base
