import axios, { AxiosInstance } from 'axios'
import { ElMessageBox } from 'element-plus'

const ApiUrl = 'https://wx.dpxtd.com';
//const ApiUrl = 'http://192.168.1.108:8081'

const Apis: any = {
  Api: `${ApiUrl}/`,
}
const request = {
  resolve: (config: any) => {
    config.headers = Object.assign(
      {
        'Content-Type': 'application/json;charset=UTF-8',
        'token': localStorage.getItem('token')
      },
      config.headers || {}
    )
    return config
  },
  reject: (err: any) => {
    // eslint-disable-next-line
    return Promise.reject(err)
  },
}

const response = {
  resolve: {
    default: (response: any) => {
      const config = response.config || {}
      const url = config?.url || config?.baseURL || ''
      const requestInfo = {
        url,
        data: config.data,
        params: config.params,
        method: config.method,
        headers: {
        },
      }
      let needHeader = false;
      if (config.data && config.data.indexOf('header=1') != -1) {
        needHeader = true;
      }
      if (response.status == 200) {
        let code = response.data.status || response.data.code;
        const reg = /^2\d{2}$/
        if (code == 0) {
          ElMessageBox({
            type: 'error',
            message: response.data.message || '操作失败'
          })
          return;
        }
        if (code == 402 || code == 500) {
          ElMessageBox.confirm('用户会话过期', '警告', {
            confirmButtonText: '前往登录',
            cancelButtonText: '取消',
            type: 'warning',
          })
            .then(() => {
              location.href = '/user/login?ref='+ encodeURIComponent(location.href)
            })
            .catch(() => { })

            return;
        }

        if (code && !reg.test(code)) {
          if ((code + '').length < 4) {
            ElMessageBox({
              type: 'error',
              message: response.data.message || '操作失败'
            })
            //业务错误码
            return Promise.reject({ error: response.data.message, status: code })
          }
        }
        if (needHeader) {
          return {
            data: response.data.data ? response.data.data : response.data,
            response: response
          }
        } else {
          return response.data.data ? response.data.data : response.data
        }
      } else {
        ElMessageBox({
          type: 'error',
          message: response.data.message || '操作失败'
        })
        return Promise.reject({
          error: response.data.message,
          status: response.status,
        })
      }
    },


  },
  reject: (error: any) => {
    const config = error.config || {}
    const url = config?.url || config?.baseURL || ''
    const requestInfo = {
      url,
      data: config.data,
      params: config.params,
      method: config.method,
      headers: {
        orgid: config.headers?.orgid,
        source: config.headers?.source,
      },
    }
    if (error && error.response && error.response.status) {
      const status = error.response.status + ''
      // const re4xError = /^4..$/
      const re5xError = /^5..$/
      const re4xError = /^403$/
      if (re4xError.exec(status)) {
        // 未授权，请重新登录

      } else {
        let data = error.response.data
        if (data) {
          ElMessageBox({
            type: 'error',
            message: data.msg || '操作失败'
          })

          return Promise.reject({ error: data.msg, status: data.status })
        } else {
          ElMessageBox({
            type: 'error',
            message: error || '操作失败'
          })

          return Promise.reject({ error: error + '', status: status })
        }
      }
    } else {
      if (error instanceof Error) {
        ElMessageBox({
          type: 'error',
          message: error.message || '操作失败'
        })

      } else {
        ElMessageBox({
          type: 'error',
          message: error || '操作失败'
        })
      }

      //网络错误
      return Promise.reject({ error: error + '', status: 404 })
    }
    // TODO 登录验证
    //return Promise.reject({ error: error + ',unknow' })}
  },
}

const http: Record<string, AxiosInstance> = {}

Object.keys(Apis).forEach(key => {
  http[key] = axios.create({
    baseURL: Apis[key] as string,
    // withCredentials: true,
    validateStatus: status => {
      return status >= 200 && status < 300
    },
  })
  // 请求拦截
  http[key].interceptors.request.use(request.resolve, request.reject)
  // 响应拦截
  http[key].interceptors.response.use(
    response.resolve.default,
    response.reject
  )
})
export const Api = http['Api'];
export default http
